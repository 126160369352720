.container {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
}

.articleContainer {
    margin-top: 20px;
    text-align: left;
    border: solid 1px black;
    padding: 30px;
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #007acc;
}

.date {
    font-size: 1rem;
    color: #888;
    margin-bottom: 20px;
    
}

.content {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
    
}

.links {
    margin-top: 30px;
}

.link {
    display: block;
    color: #007acc;
    margin: 10px 0;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.tags {
    margin-top: 30px;
}

.tag {
    display: inline-block;
    background-color: #e0e0e0;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #555;
}

.tag:hover {
    background-color: #007acc;
    color: #fff;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .title {
        font-size: 1.5rem;
    }

    .content {
        font-size: 1rem;
    }

    .container {
        padding: 10px;
    }
}
