.mb3{
    border: solid 1px black;
    margin-bottom: 5px;
    border-radius: 5px;
}


.full{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

