.main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main2{
    min-width :15rem;
    min-height:50vh ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
   
}

.inputl{
    min-width: 18rem;
    border: solid 1px black;
}


@media screen and (max-width:868px) {

    .main{
        min-width: 20rem;
    }

    .main2{
        min-width:  18rem;
    }

    .inputl{
        min-width: 10rem;
    }
    
}