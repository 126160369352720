.nav{
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(77,81,84,255);
}


.nav ul{
    display:inline-flex;
    list-style: none;
    
    color: aliceblue;
}


.nav ul li{
   margin: 0 5px 0 5px;
   display:flex;
   padding: 5px;
   flex-direction: column;
   justify-content: center;
    width: auto;
}

.nav ul li a{
    text-decoration: none;
    color: white;
}



.submenu{
    display: none;
}

.nav ul li:hover .submenu{
    display: block;
    position: absolute;
    background-color: rgb(255, 255, 255);
    margin-top: 145px;
    z-index: 1000;
    border-radius: 5%;
    border: solid 0.05rem rgba(76, 76, 76, 0.734);
}

.nav ul li:hover .submenu ul{
    display: block;
    margin-left: -10px;
}

.nav ul li:hover .submenu ul li{
    width: auto;
    min-width: 75px;
    padding: 5px;
    
    background: transparent;
    border-radius: 0;
    text-align: left;
    color: rgb(4, 4, 4);
}

.nav ul li:hover .submenu ul li:hover{
    cursor: pointer;
    border-bottom: solid 1px black;
}