.full {
    display: flex;
    flex-direction: row;
}

.middle {
    margin: 0;
    padding: 0;
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

p {
    margin: 0;
    padding: 0;

}

.right {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%;
    border: solid 0.5px black;
    position: sticky;
    top: 0;
}

ul {
    list-style: none;
}

ul li label {
    margin: 2px;
}

.mobileright {
    display: none;
}

.item {
    width: 15rem;

}



.item img {
    height: 20vh;
    object-fit: cover;
}


.filter {
    display: none;
}

@media screen and (max-width:856px) {
    .full {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .right {
        display: none;
    }

    .mobileright {
        display: block;
        align-items: center;
        justify-content: center;
    }


    .micons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 5px;
        padding: 2px;
        flex-wrap: nowrap;
    }

    .item p {
        margin: 1px;
        padding: 1px;
    }


    .insidecard {
        display: flex;
        flex-direction: column;
    }

    .filter {
        display: block;
    }
}