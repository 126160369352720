.mobile{
    display: none;
}

.desktop{
    display: block;
}

@media screen and (max-width:856px) {
    .mobile{
        display: block;
    }

    .desktop{
        display: none;
    }


    
}