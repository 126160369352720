.left{
    width:15%;
    border: solid 1px black;
}

.right{
    width: 85%;
    border: solid 1px red;
}

.main{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1px;
}



