.privacypolicy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    color: #333;
  }
  
  .privacypolicy h1 {
    text-align: center;
    color: #616161;
  }
  
  .privacypolicy h2 {
    color: #3a3a3a;
    margin-top: 20px;
  }
  
  .privacypolicy ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacypolicy footer {
    margin-top: 30px;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  
  .privacypolicy footer address {
    font-style: normal;
  }
  