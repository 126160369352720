.container {
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
}

.header {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.articleCard {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px;
    margin: 15px;
    text-align: left;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.articleCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    background-color: #fff;
}

.articleTitle {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007acc;
}

.articleSnippet {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
    line-height: 1.6;
}

.readMoreButton {
    display: inline-block;
    padding: 8px 16px;
    font-size: 1rem;
    color: #fff;
    background-color: #007acc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.readMoreButton:hover {
    background-color: #005f99;
}

.noArticles {
    font-size: 1.2rem;
    color: #888;
    margin-top: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .articleCard {
        padding: 10px;
    }

    .articleTitle {
        font-size: 1.2rem;
    }

    .articleSnippet {
        font-size: 0.9rem;
    }

    .container {
        padding: 10px;
    }

    .row{
        flex-direction: column;
    }
}
