.content {
  display: flex;
  flex-direction: row;
  min-height: 50vh;
}

.latestnews {
  width: 30%;
}

.cardimage {
  height: 30vh;
}

.sitenews {
  width: 30%;
}

.middle {
  width: 40%;
}



.featuresContainer {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  background-color: #f9f9f9;
}

.text-center {
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #333;
}

.featureCard {
  text-decoration: none;
  color: inherit;
  /* Preserve default text styles */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 280px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.featureCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}


.cardIcon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #007bff;
}

.cardTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
}

.cardDescription {
  font-size: 1rem;
  color: #555;
}

.homeContainer {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.header {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.articleRow {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.articleCard {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex: 1 1 calc(33% - 20px);
  box-sizing: border-box;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.articleCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.articleTitle {
  font-size: 1.6rem;
  color: #007acc;
  margin-bottom: 10px;
}

.articleSnippet {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.5;
}

.readMoreButton {
  padding: 8px 15px;
  font-size: 1rem;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.readMoreButton:hover {
  background-color: #005f99;
}

.noArticles {
  font-size: 1.2rem;
  color: #888;
  margin-top: 20px;
  text-align: center;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .articleCard {
    flex: 1 1 100%;
  }

  .header {
    font-size: 2rem;
  }
}




@media screen and (max-width:856px) {
  .latestnews {
    display: none;
  }

  .sitenews {
    display: none;
  }

  .middle {
    width: 100%;
  }
}