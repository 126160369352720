.btnsize{
    font-size: 5rem;
    font-weight: 750;
}



p{
    margin: 1px;
    padding: 1px;
   
}

.mobpla{
    display: none;
}

.deskplace{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.rower{
    display: flex;
    flex-direction: row;
    margin: 3px;
    justify-content: space-around;   
}

.columner{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:868px) {

    .mobbtns{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        
        margin: 0;
    }

    .btnsize{
        font-size: 5rem;
        font-weight: 750;
    }

    p{
        margin: 0;
    }

    .mobpla{
        display: block;
    }
    

    .deskplace{
        display: none;
    }
}